<template>
  <div
    class="cursor-pointer duration-200 flex flex-row mt-3 p-5 rounded-sm transition w-full"
    :class="[
      isActive ? 'bg-corporate-800' : 'bg-corporate-900',
      bankStatementUploaded ? 'hiddens' : 'blocks'
    ]"
  >
    <div
      class="flex h-8 items-center justify-center mt-1 rounded-full w-8"
      :class="isActive ? 'bg-white' : 'bg-gray-300'"
    >
      <ion-icon name="checkmark-outline" v-if="isActive"></ion-icon>
    </div>
    <div
      class="flex flex-col ml-8"
      :class="isActive ? 'text-white' : 'text-black'"
    >
      <h4 class="font-medium mt-1 text-12">
        {{ label }}
      </h4>
      <h4 class="font-thin mt-3 text-10">
        {{ description }}
      </h4>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isDone: {
      type: Boolean,
      default: false
    },
    option: {
      type: Object,
      default: () => null
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    description() {
      return this.option?.description;
    },
    isActive() {
      return this.isSelected || this.isDone;
    },
    label() {
      return this.option?.label;
    }
  }
};
</script>
